import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { Typography,Stack,Grid, Box} from '@mui/material';
import Discover from '../components/discover'
import cardsData from '../data/aboutwork';
import { Dna } from '../components/dna';
import { LinkedIn } from '@mui/icons-material';
import Accordian from "../components/accordian";

export default function Example() {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Who we are - C4Scale</title>
          <meta name="description" content="Custom Software development &amp; custom software developer services at C4Scale" />
        </Helmet>
        <Stack className='bg-indigo-600' sx={{padding:{xs:"60px 30px",md:"80px 150px"}}}>
      <h1 className='text-3xl md:text-4xl font-semibold text-[#fff] '>
      Passionate, Entrepreneurial,<br/><span className='relative top-4'>
Diligent.</span>
      </h1>
    </Stack>
    
    <h2 className="text-2xl lg:text-4xl font-semibold text-center mt-10" id='discover'>
    Leadership Team
      </h2>
      <Stack padding={{xs:"30px",md:"40px 100px"}} sx={{marginBottom:"40px"}} justifyContent="center" alignItems="center">
    <Stack direction={{xs:"column",lg:"row"}} gap="20px" className='bg-gray-50' sx={{width:"90%" , boxShadow:"0px 15px 39px 0px #d2d2d2"}}>
    <Box component="img" src="https://res.cloudinary.com/durti17dk/image/upload/v1716885160/blog-assets/z_yfyj9g_kz7wig.webp" sx={{height:{xs:"200px",lg:"390px"},objectFit:"contain"}} />
    <Stack direction="column" gap="7px" mt="20px" padding="0px 20px">
        <Typography sx={{fontSize:"30px", fontWeight:"600"}}>
        Chakravarthy Varaga
        </Typography>
        <Typography className='text-indigo-600 font-semibold'  sx={{fontSize:"14px"}}>
        Founder & CEO
        </Typography>
        <Typography fontSize="15px" mt="8px">
        Chakravarthy Varaga is an Engineering Leader, with a startup mindset &amp; an entrepreneurial spirit, building Data Platforms, Products over his 19 years of experience across Startups, EdTech, Large Telco & Enterprises. He’s an hands-on leader that hired and led high performing teams. Before founding C4Scale, he spent 3.8 years with Gojek (Indonesia) leading multiple platforms in the DataOrg. He founded a marketing automation platform, user-behaviour analytics platform/Clickstream, personalization, user segmentation & journeys, adtech, A/B Tests Experimentation platform catering to 100s of millions of users in South-East Asia. Before Gojek, he spent a decade in Europe working for HMH (Ireland), Ericsson (Ireland), Novel, Wipro Ltd. serving in global tech engineering positions enabling products, data platforms in SaaS, EdTech, telecom that cater to world’s 2G/3G/4G traffic.  He’s a versatile leader with a vision, strategy and known for moving from abstract unknowns to predictable outcomes.
        </Typography>
        <Link to='https://www.linkedin.com/in/chakravarthyvp/ ' target='blank'><LinkedIn sx={{fontSize:"30px",mb:"20px"}}/></Link>
    </Stack>

    </Stack>
    </Stack>
    <Accordian/>
    <Dna/>

    <h2 className="text-2xl lg:text-4xl font-semibold text-center mt-8" id='discover'>
      Some of our work
      </h2>
      <div className='scroll' style={{ overflowX: "auto", whiteSpace: "nowrap", width: "100%",marginBottom:"30px" }}>
        <Grid container spacing={2} sx={{justifyContent:{xs:"flex-start",md:"center"}, gap: "25px", padding: { xs: "40px 20px", md: "40px 40px 30px 60px" }, flexWrap: "nowrap", margin: "0px",width:"98%" }}>
          {cardsData.map((card, index) => (
            <Stack key={index} style={{ display: "inline-block", background: index % 2 === 0 ? "#fbfafb" : "#e7edfb", padding: "0px", margin: "0px", minWidth: "380px", maxWidth: "380px" }}>
              <Discover {...card} />
            </Stack>
          ))}
        </Grid>
      </div>
     

    </Layout>
  )
}
